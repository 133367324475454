import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Layout } from "./pages/Layout";
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <>
      <Helmet>
        <title>구장먹거리</title>
        <meta
          name="description"
          content="이제는 각 구장마다 먹거리가 무엇이 있는지 검색하지 말고 앱 하나로 해결하세요!"
        />
        <link rel="canonical" href="http://www.gujangfood.com"></link>
        <meta name="robots" content="index,follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <Layout />
    </>

    // <div className="App">
    // <Routes>
    //   <Route element={<Layout />}>
    //     <Route path="/*" element={<Navigate replace to="/home" />} />
    //     <Route path="/home" index element={<Home />} />
    //     {/* <Route path="/booking" index element={<Booking />} /> */}

    //     {/* <Route path="/mypage/:type" index element={<MyPage />} />
    //         <Route path="/accountissuance" index element={<VirtualAccountIssuance />} />
    //         <Route path="/board" index element={<Request />} />
    //         <Route path="/board" index element={<Request />} />
    //         <Route path="/design" index element={<TailwindSample />} /> */}
    //   </Route>
    // </Routes>
    // </div>
  );
}
export default App;
